import React from "react";
// import donio from '../assets/donio.png'
import do99 from '../assets/do99.jpg'

import "./Services.css"

export default function Services() {
  return (
    <div id="services" className="sizesss" style={{ 
        marginTop: "50px", 
        backgroundImage: `url(${do99})`,
        backgroundSize: 'cover', 
        // backgroundSize: '100%',
        backgroundRepeat  : 'no-repeat',
        backgroundPosition: 'center center',
        overflow: 'hidden',
        // backgroundAttachment: 'fixed',
        width: '100%',
        }}>
      <div>
        <h1>Sherbimet</h1>
      {/* <a href="tel:0682587994">doni</a> */}
      </div>
      <div style={{ paddingTop: '50px', width: '75%', height: 'auto', position: 'relative', margin: '0 auto'  }}>
        <h3 style={{textAlign: 'start',paddingTop: '30px' }}>Riparimi</h3>
        <h6 style={{paddingTop: '20px' }}>
        Riparojmë të gjitha llojet e pajisjeve elektroshtëpiake dhe ofrojmë një diagnozë profesionale të defektit, 
        riparimin e nevojshëm dhe kostot e shkaktuara. Kur nuk është e mundur të rregulloni pajisjen tuaj, ne ofrojmë 
        t'ju shesim një pajisje me cilësi të njëjtë ose më të mirë në varësi të nevojave tuaja.         </h6>
        <h3 style={{textAlign: 'start',paddingTop: '30px' }}>
        Shitja e pajisjeve
        </h3>
        <h6 style={{paddingTop: '20px' }}>
        Ne rekomandojmë pajisje shtepiake të përshtatura për përdorimin që do të jepet, me efikasitet të mirë energjie dhe pa zhurme.
        Lavatrice , Lavastovilje, tharëse, pjatalarëse, ngrohës, furra, frigoriferë, ngrirës...        </h6>
        <h3 style={{textAlign: 'start',paddingTop: '30px'}}>
        Pjese nderrimi
        </h3>
        <h6 style={{paddingTop: '20px' }}>
        Ne kryejmë instalimin e pajisjeve elektroshtëpiake. Ne përshtatim vendin për montimin e duhur të pajisjeve të reja shtëpiake 
        si lavatriçe, pjatalarëse, tharëse, furra, pllakë gatimi, pjanura qeramike, frigoriferë dhe ngrirës.       </h6>
        <h3 style={{textAlign: 'start',paddingTop: '30px'}}>
        Instalimet Elektrike dhe Hidraulike
        </h3>
        <h6 style={{paddingTop: '20px' }}>
        Kryejmë instalimin e pjeses elektrike te shpise, verifikimin e vendit si dhe skicen e dizanjon ku duhen vendosu
         pajisjet elektroshtepiake , prizat , pajisjet hidraulike etj. Konstatimi , Verifikimi dhe diskutimi sesi do kryhet procesi i punes behet falas !   </h6>
      </div>
    </div>
  );
}

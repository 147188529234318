import React from 'react'
import "./Foto.css"

import d98 from "../assets/d98.jpeg"
import d55 from "../assets/d55.jpg"

export default function Foto() {
  return (
    <div id="cmimet">
    <section id="favs">
    <div class="container">
    <h3 style={{ paddingTop: '90px'}}>Lista e Cmimeve</h3>
    <h6>Kliko mbi foto per te marr informacioni perkates</h6>
        <div class="container pt-3 pb-5">
            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3 p-3">
                    <div class="card text-white">
                       <img class="card-img" src={d98} alt="Cardimage" />
                       <div class="card-img-overlay ovl">
                           <h4 class="card-title">Insalimet Elektrike</h4>
                           <p class="card-text">Monitimi i komplet instalimeve elekrtike ne shtepi sebashku me sistemin e panelit (automatin) </p>
                           <p class="card-text">45'000 - 85'000 leke</p>
                       </div>
                    </div>                        
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3 p-3">
                    <div class="card text-white">
                       <img class="card-img" src="https://halilaj.com.al/wp-content/uploads/deep-groove-ball-bearing.png" alt="Cardimage" />
                       <div class="card-img-overlay ovl">
                           <h4 class="card-title">Riparime elektroshtepiake</h4>
                           <p class="card-text"> Lavatrice , soba , dushe , lavastovilje, etj</p>
                           <p class="card-text">1'000 - 8'500 leke</p>
                       </div>
                    </div>      
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3 p-3">
                    <div class="card text-white">
                       <img class="card-img" src={d55} alt="Cardimage" />
                       <div class="card-img-overlay ovl">
                           <h4 class="card-title">Riparime hidraulike</h4>
                           <p class="card-text">Riparim dhe montimi i sistemit te hidraulikeve ne shtepite tuaja</p>
                           <p class="card-text">1'000 deri +50'000 leke</p>
                       </div>
                    </div>      
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3 p-3">
                    <div class="card text-white">
                       <img class="card-img" src="https://assets.afcdn.com/story/20210330/2114524_w2201h2201c1cx1101cy681cxt0cyt0cxb2201cyb1362.jpg" alt="Cardimage" />
                       <div class="card-img-overlay ovl">
                           <h4 class="card-title">Konstatimi i problemit</h4>
                           <p class="card-text"></p>
                           <p class="card-text">FALAS</p>
                       </div>
                    </div>      
                </div>
            </div>
            {/* <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3 p-3">
                    <div class="card text-white">
                       <img class="card-img" src="https://assets.afcdn.com/story/20210330/2114524_w2201h2201c1cx1101cy681cxt0cyt0cxb2201cyb1362.jpg" alt="Cardimage" />
                       <div class="card-img-overlay ovl">
                           <h4 class="card-title">Riparime</h4>
                           <p class="card-text">Riparim elektroshtepiakeRiparim elektroshtepiakeRiparim elektroshtepiakeRiparim elektroshtepiake</p>
                           <p class="card-text">$100</p>
                       </div>
                    </div>                        
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3 p-3">
                    <div class="card text-white">
                       <img class="card-img" src="https://assets.afcdn.com/story/20210330/2114524_w2201h2201c1cx1101cy681cxt0cyt0cxb2201cyb1362.jpg" alt="Cardimage" />
                       <div class="card-img-overlay ovl">
                           <h4 class="card-title">Riparime</h4>
                           <p class="card-text">Riparim elektroshtepiakeRiparim elektroshtepiakeRiparim elektroshtepiakeRiparim elektroshtepiake</p>
                           <p class="card-text">$100</p>
                       </div>
                    </div>      
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3 p-3">
                    <div class="card text-white">
                       <img class="card-img" src="https://assets.afcdn.com/story/20210330/2114524_w2201h2201c1cx1101cy681cxt0cyt0cxb2201cyb1362.jpg" alt="Cardimage" />
                       <div class="card-img-overlay ovl">
                           <h4 class="card-title">Riparime</h4>
                           <p class="card-text">Riparim elektroshtepiakeRiparim elektroshtepiakeRiparim elektroshtepiakeRiparim elektroshtepiake</p>
                           <p class="card-text">$100</p>
                       </div>
                    </div>      
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3 p-3">
                    <div class="card text-white">
                       <img class="card-img" src="https://assets.afcdn.com/story/20210330/2114524_w2201h2201c1cx1101cy681cxt0cyt0cxb2201cyb1362.jpg" alt="Cardimage" />
                       <div class="card-img-overlay ovl">
                           <h4 class="card-title">Riparime</h4>
                           <p class="card-text">Riparim elektroshtepiakeRiparim elektroshtepiakeRiparim elektroshtepiakeRiparim elektroshtepiake</p>
                           <p class="card-text">$100</p>
                       </div>
                    </div>      
                </div> */}
            {/* </div> */}
        </div>
    </div>
</section>
</div>
  )
}

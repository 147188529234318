import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Navbar1 from './components/navbar';
import Caruseli1 from './components/Caruseli1';
import BiznesiFamiljar from './components/BiznesiFamiljar';
import Punet from './components/Punet';
import NrPuneve from './components/NrPuneve';
import Services from './components/Services';
import Foto from './components/Foto';
import Footer from './components/Footer';
import Addresa from './components/Addresa';
import ImgSlider from './components/ImgSlider';


const url = `//api.whatsapp.com/send?phone=+355682587994&text= Pershendetje, isha i interesuar per te marre me shume informacion mbi sherbimet qe ofroni!`


function App() {
  return (
    <div className="App">
      <Navbar1 />
      <Caruseli1 />
      {/* <BiznesiFamiljar /> */}
      <Punet />
      <NrPuneve />
      <Services />
      <Foto />
      <ImgSlider />
      <Addresa />
      <Footer />



      <a
        // href="https://wa.me/+355682587994&text= Pershendetje, isha i interesuar per te riparuar instalimet/elektroshtepiaken time ? Mund te marr me shume informacion ju lutem?"
        href={url}
        class="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-whatsapp whatsapp-icon"></i>
      </a>
    </div>
  );
}

export default App;

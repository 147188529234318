import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
// import NavDropdown from "react-bootstrap/NavDropdown";
// import { useEffect, useState } from "react";

function Navbar1() {
  // const [sticky, setSticky] = useState(false);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     setSticky(window.scrollY > 200);
  //   };
  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // });

  // useEffect(() => {
  //   document.getElementById("navbar").scrollIntoView();
  // }, []);

  return (
    <Navbar
      id="navbar"
      // className={`${sticky ? "sticky" : ""}`}
      collapseOnSelect
      expand="lg"
      // bg="dark"
      variant="dark"
      style={{
        background: "linear-gradient(to right, #000000 , #DD0000, #FFCE00)",
        // boxShadow: "0px 5px 18px rgba(0,0,0,0.6)",        
        // top: "0px",
        // zIndex: "300",
        // position: "sticky",
      }}
    >
      <Container>
        <Navbar.Brand href="#home">Riparime Elektrike</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="#sherbimet">Sherbimet</Nav.Link>
            <Nav.Link href="#services">Servis</Nav.Link>
            <Nav.Link href="#numbers">Punet e kryera</Nav.Link>
            <Nav.Link href="#cmimet">Lista e Cmimeve</Nav.Link>
            {/* <NavDropdown title="Dropdown" id="collasible-nav-dropdown">
              <NavDropdown.Item href="#sherbimet">Lista e Cmimeve</NavDropdown.Item>
              <NavDropdown.Item href="#sherbimet">Another action</NavDropdown.Item>
              <NavDropdown.Item href="#sherbimet">Something</NavDropdown.Item> */}
              {/* <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item> */}
            {/* </NavDropdown> */}
          </Nav>
          <Nav>
            <Nav.Link href="#addresa">Addresa</Nav.Link>
            <Nav.Link eventKey={2} href="tel:+355682587994">
              Kontaktoni: 0682587994
            </Nav.Link>

            {/* <a href="tel:555-555-5555"><img src="path/to/phone/icon.jpg" alt="Call 555-555-5555" /></a> */}


          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navbar1;







// import Container from 'react-bootstrap/Container';
// import Nav from 'react-bootstrap/Nav';
// import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';

// function CollapsibleExample() {
//   return (
//     <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
//       <Container>
//         <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand>
//         <Navbar.Toggle aria-controls="responsive-navbar-nav" />
//         <Navbar.Collapse id="responsive-navbar-nav">
//           <Nav className="me-auto">
//             <Nav.Link href="#sherbimet">Features</Nav.Link>
//             <Nav.Link href="#pricing">Pricing</Nav.Link>
//             <NavDropdown title="Dropdown" id="collasible-nav-dropdown">
//               <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
//               <NavDropdown.Item href="#action/3.2">
//                 Another action
//               </NavDropdown.Item>
//               <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
//               <NavDropdown.Divider />
//               <NavDropdown.Item href="#action/3.4">
//                 Separated link
//               </NavDropdown.Item>
//             </NavDropdown>
//           </Nav>
//           <Nav>
//             <Nav.Link href="#deets">More deets</Nav.Link>
//             <Nav.Link eventKey={2} href="#memes">
//               Dank memes
//             </Nav.Link>
//           </Nav>
//         </Navbar.Collapse>
//       </Container>
//     </Navbar>
//   );
// }

// export default CollapsibleExample;
import React from "react";

export default function Addresa() {
  return (
    <section>
      <div id="addresa" class="map">
        <iframe
        title="addresa"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2996.6518612479517!2d19.772504615344886!3d41.31643660835363!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1350303a7da9fa77%3A0xe76c80f2f8571b3!2sRruga%20Hamdi%20Cullhaj%2C%20Tirana%2C%20Albania!5e0!3m2!1sen!2s!4v1674931534992!5m2!1sen!2s"
            style= {{
                border: "0px",
                width: "100%",
                height: "450px",
                frameborder: "0",
                // style: "border: 0",
            // allowfullscreen="",
            // referrerpolicy="no-referrer-when-downgrade"
            }}
            // loading="lazy"
          >
        </iframe>
      </div>
    </section>
  );
}

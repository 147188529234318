import React, { Fragment } from "react";
import MediaQuery from "react-responsive";
import rpr from "../assets/rpr.png";
import "./biznes.css";
import s1 from "../assets/s1.png"

function BiznesiFamiljar() {
  return (
    <Fragment>
      <MediaQuery minDeviceWidth={701}>
        <div
          style={{
            // backgroundImage: "linear-gradient(to right, #DECBA4, #3E5151)",
            // backgroundImage: `url(${s1})`,
            // backgroundSize: 'cover', 
            // overflow: 'hidden',
            marginBottom: '50px'
          }}
        >
          <div
            class="media"
            style={{ width: "100%", alignItems: "flex-start",
            backgroundImage: `url(${s1})`,
            backgroundSize: 'cover', 
            overflow: 'hidden',
            // backgroundImage: 'linear-gradient(to right, #DECBA4, #3E5151)'
          }}
          >
            <div class="image" style={{ paddingLeft: "55px" }}>
              <img
                src={rpr}
                alt="MDN logo"
                style={{ width: "350px", height: "350px" }}
              />
            </div>
            <div class="content" style={{paddingRight: '50px', paddingTop: '50px'}}>
              <h1>Sandri</h1>
              This is the content of my media object. Items directly inside the
              flex container will be aligned to flex-start.his is the content of my media object. Items directly inside the
              flex container will be aligned to flex-start.his is the content of my media object. Items directly inside the
              flex container will be aligned to flex-start.his is the content of my media object. Items directly inside the
              flex container will be aligned to flex-start.his is the content of my media object. Items directly inside the
              flex container will be aligned to flex-start.his 
            </div>
          </div>
        </div>
      </MediaQuery>

      <MediaQuery maxDeviceWidth={700}>
        <div style={{ backgroundImage: "linear-gradient(red, yellow)", marginBottom: '30px' }}>
          <div
            class="media"
            style={{display: "flex",flexDirection: "column",AlignItems: "center",}}>
            <div
              class="image"
              style={{ justifyContent: "center", AlignItems: "center" }}>
              <img
                src={rpr}
                alt="MDN logo"
                style={{ width: "250px", height: "250px", }}/>
            </div>
            <div class="content">
              This is the content of my media object. Items directly inside the
              flex container will be aligned to flex-start.his is the content of my media object. Items directly inside the
              flex container will be aligned to flex-start.his is the content of my media object. Items directly inside the
              flex container will be aligned to flex-start.
            </div>
          </div>
        </div>
      </MediaQuery>
    </Fragment>
  );
}

export default BiznesiFamiljar;

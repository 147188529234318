import React, {useState} from 'react'
import "./NrPuneve.css"
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import svgg from '../assets/svgg.png'

export default function NrPuneve() {

    const [counterOn, setCounterOn] = useState(false);


  return (
    <ScrollTrigger
    onEnter={() => setCounterOn(true)}
    onExit={() => setCounterOn(false)}
  >
    <section id="numbers" style={{ backgroundImage: `url(${svgg})`, backgroundSize: 'cover', overflow: 'hidden',}}>
        <div class="container">
            
            <div class="text-center text-light pb-5">
                <h2 class="text-dark">Numri i puneve te mbyllura</h2>
            </div>
            <div class="row text-center text-light">
                <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                    <h2 class="numc mx-auto"> +
                    {counterOn && <CountUp start={9} end={22} duration={3} delay={0} />}
                    </h2>
                    <h3 class="numd">Vite Eksperienc</h3>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                    <h2 class="numc mx-auto">
                    {counterOn && <CountUp start={8290} end={10975} duration={3} delay={0} />}
                    </h2>
                    <h3 class="numd">Lavatrice</h3>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                    <h2 class="numc mx-auto">
                    {counterOn && <CountUp start={1000} end={4860} duration={3} delay={0} />}
                    </h2>
                    <h3 class="numd">Elektrike</h3>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                    <h2 class="numc mx-auto">
                    {counterOn && <CountUp start={1000} end={3390} duration={3} delay={0} />}
                    </h2>
                    <h3 class="numd">Soba dhe Lavastovilje</h3>
                </div>
            </div>
        </div>
    </section>
    </ScrollTrigger>
  )
}

import React from "react";
import Carousel from "react-bootstrap/Carousel";
import "./ImgSlider.css";

export default function ImgSlider() {
  return (
    <div style={{ marginBottom: "50px" , height: '250px' }}>
      <Carousel>
        <Carousel.Item>
          <div class="carousel-inner doni">
            <img
              style={{ width: "33%", height: "50%" }}
              className="inline-block w-25 p-3"
              src="https://1000logos.net/wp-content/uploads/2020/05/Candy-logo.jpg"
              alt="First slide"
            />
            <img
              style={{ width: "33%", height: "50%" }}
              className="inline-block w-25 p-3"
              src="https://1000logos.net/wp-content/uploads/2017/12/Miele-logo.png"
              alt="First slide"
            />
            <img
              style={{ width: "33%", height: "50%" }}
              className="inline-block w-25 p-3"
              src="https://1000logos.net/wp-content/uploads/2017/06/Samsung-Logo-2.png"
              alt="First slide"
            />
          </div>
          {/* <div class="carousel-inner doni">
         <img
          className="inline-block w-25 p-3"
          src="https://images.squarespace-cdn.com/content/v1/502a8efb84ae42cbccf920c4/1585574686746-VCDIHSO21O76WR72WIAD/LG-Logo.png?format=750w"
          alt="First slide"
        />
         <img
          className="inline-block w-25 p-3"
          src="https://1000logos.net/wp-content/uploads/2017/12/Miele-logo.png"
          alt="First slide"
        />
         <img
          className="inline-block w-25 p-3"
          src="https://logos-world.net/wp-content/uploads/2020/12/Electrolux-Logo.png"
          alt="First slide"
        />
        </div> */}
        </Carousel.Item>

        <Carousel.Item>
          <div class="carousel-inner doni">
            <img
              style={{ width: "33%", height: "50%" }}
              className="inline-block w-25 p-3"
              src="https://images.squarespace-cdn.com/content/v1/502a8efb84ae42cbccf920c4/1585574686746-VCDIHSO21O76WR72WIAD/LG-Logo.png?format=750w"
              alt="First slide"
            />
            <img
              style={{ width: "33%", height: "50%" }}
              className="inline-block w-25 p-3"
              src="https://1000logos.net/wp-content/uploads/2017/12/Miele-logo.png"
              alt="First slide"
            />
            <img
              style={{ width: "33%", height: "50%" }}
              className="inline-block w-25 p-3"
              src="https://logos-world.net/wp-content/uploads/2020/12/Electrolux-Logo.png"
              alt="First slide"
            />
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div class="carousel-inner doni">
            <img
              style={{ width: "33%", height: "50%" }}
              className="inline-block w-25 p-3"
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/bf/Midea.svg/2560px-Midea.svg.png"
              alt="First slide"
            />
            <img
              style={{ width: "33%", height: "50%" }}
              className="inline-block w-25 p-3"
              src="https://1000logos.net/wp-content/uploads/2020/10/Smeg-Logo.png"
              alt="First slide"
            />
            <img
              style={{ width: "33%", height: "50%" }}
              className="inline-block w-25 p-3"
              src="https://www.industriall-union.org/sites/default/files/uploads/images/Company-Logos/bosch.jpg"
              alt="First slide"
            />
          </div>
          {/* <div class="carousel-inner doni">
        <img
          className="inline-block w-25 p-3"
          src="https://www.siemens-home.bsh-group.com/store/medias/sys_master/root/hc0/h3d/11013070225438/GL-SIE-Logo-Siemens-Petrol-RGB-BSH-Reshape-2021.png"
          alt="First slide"
        />
         <img
          className="inline-block w-25 p-3"
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6d/Allgemeine_Elektricit%C3%A4ts-Gesellschaft_%28logo%29.svg/1200px-Allgemeine_Elektricit%C3%A4ts-Gesellschaft_%28logo%29.svg.png"
          alt="First slide"
        />
         <img
          className="inline-block w-25 p-3"
          src="https://cdn.wpsandwatch.com/bundles/whirlpoolindesitmain/custom/image/logo_indesit_ENG.png"
          alt="First slide"
        />
        </div> */}
        </Carousel.Item>

        <Carousel.Item>
          <div class="carousel-inner doni">
            <img
              style={{ width: "33%", height: "50%" }}
              className="inline-block w-25 p-3"
              src="https://www.siemens-home.bsh-group.com/store/medias/sys_master/root/hc0/h3d/11013070225438/GL-SIE-Logo-Siemens-Petrol-RGB-BSH-Reshape-2021.png"
              alt="First slide"
            />
            <img
              style={{ width: "33%", height: "50%" }}
              className="inline-block w-25 p-3"
              src="https://cdn.wpsandwatch.com/bundles/whirlpoolindesitmain/custom/image/logo_indesit_ENG.png"
              alt="First slide"
            />
            <img
              style={{ width: "33%", height: "50%" }}
              className="inline-block w-25 p-3"
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/cd/AEG2016_logo.png/1200px-AEG2016_logo.png"
              alt="First slide"
            />
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <div class="carousel-inner doni">
            <img
              style={{ width: "33%", height: "50%" }}
              className="inline-block w-25 p-3"
              src="https://mixrepairs.co.uk/mixV2/wp-content/uploads/2019/12/zanussi.jpeg"
              alt="First slide"
            />
            <img
              style={{ width: "33%", height: "50%" }}
              className="inline-block w-25 p-3"
              src="https://upload.wikimedia.org/wikipedia/commons/9/95/Whirlpool_Corporation_Logo_%28as_of_2017%29.svg"
              alt="First slide"
            />
            <img
              style={{ width: "33%", height: "50%" }}
              className="inline-block w-25 p-3"
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/cd/AEG2016_logo.png/1200px-AEG2016_logo.png"
              alt="First slide"
            />
          </div>
          {/* <div class="carousel-inner doni">
        <img
          className="inline-block w-25 p-3"
          src="https://mma.prnewswire.com/media/256927/maytag_logo.jpg?p=facebook"
          alt="First slide"
        />
         <img
          className="inline-block w-25 p-3"
          src="https://ik.imagekit.io/3afupmxoz/storage/images/brand/463125-hotpoint.png"
          alt="First slide"
        />
         <img
          className="inline-block w-25 p-3"
          src="https://1000logos.net/wp-content/uploads/2017/12/Miele-logo.png"
          alt="First slide"
        />
        </div> */}
        </Carousel.Item>
        <Carousel.Item>
          <div class="carousel-inner doni">
            <img
              className="inline-block w-25 p-3"
              src="https://mma.prnewswire.com/media/256927/maytag_logo.jpg?p=facebook"
              alt="First slide"
            />
            <img
              className="inline-block w-25 p-3"
              src="https://ik.imagekit.io/3afupmxoz/storage/images/brand/463125-hotpoint.png"
              alt="First slide"
            />
            <img
              className="inline-block w-25 p-3"
              src="https://1000logos.net/wp-content/uploads/2017/12/Miele-logo.png"
              alt="First slide"
            />
          </div>
        </Carousel.Item>
      </Carousel>
    </div>
  );
}

import Carousel from "react-bootstrap/Carousel";
import React, { useState } from "react";
import MediaQuery from "react-responsive";
import { Fragment } from "react";

// import s01 from '../assets/s01.png'
import s02 from '../assets/s02.png'
// import s03 from '../assets/s03.jpeg'

// import p1 from "../assets/p1.jpg"
import p11 from "../assets/p11.jpg"

import e1 from "../assets/e1.jpg"



function Doni() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  

  return (
    <Fragment>
      <MediaQuery minDeviceWidth={701}>
        <Carousel activeIndex={index} onSelect={handleSelect}>
          <Carousel.Item>
            <img
              style={{
                width: "100%",
                height: "700px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              className="d-block"
              src={s02}
              alt="First slide"
            />
            <Carousel.Caption>
              <h3 style={{color: 'white', fontSize: '38px', paddingBottom: '42%'}}>Riparime elektroshtepiake</h3>
              {/* <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            <img
              style={{
                width: "100%",
                height: "700px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              className="d-block"
              src={e1}
              alt="Third slide"
            />

            <Carousel.Caption>
            <h3 style={{ fontSize: '38px', color: '#FFFFFF', paddingBottom: '38%'}}>Instalime elektrike te ndryshme shtepi private/apartamente</h3>
              {/* <p>
                Praesent commodo cursus magna, vel scelerisque nisl consectetur.
              </p> */}
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            <img
              style={{
                width: "100%",
                height: "700px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              className="d-block"
              src={p11}
              alt="Second slide"
            />
            <Carousel.Caption>
            <h3 style={{color: 'white', fontSize: '38px', paddingBottom: '42%'}}>Instalime dhe Riparime Hidraulike</h3>
              {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
            </Carousel.Caption>
          </Carousel.Item>

        </Carousel>
      </MediaQuery>


      <MediaQuery maxDeviceWidth={700}>
        <Carousel activeIndex={index} onSelect={handleSelect}>
          <Carousel.Item>
            <img
              style={{
                width: "100%",
                height: "400px",
                marginLeft: "auto",
                marginRight: "auto",
                // marginTop: "100px",
              }}
              className="d-block"
              src={s02}
              alt="First slide"
            />
            <Carousel.Caption>
            <h3 style={{color: 'white', fontSize: '28px', paddingBottom: '72%'}}>Riparime elektroshtepiake</h3>
              {/* <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              style={{
                width: "100%",
                height: "400px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              className="d-block"
              src={e1}
              alt="Second slide"
            />
            <Carousel.Caption>
            <h3 style={{color: 'black', fontSize: '28px', paddingBottom: '42%'}}>Instalime elektrike te ndryshme shtepi private/apartamente</h3>
              {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              style={{
                width: "100%",
                height: "400px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              className="d-block"
              src={p11}
              alt="Third slide"
            />

            <Carousel.Caption>
            <h3 style={{paddingBottom: '12%'}}>Instalime dhe Riparime Hidraulike</h3>
              {/* <p>
                Praesent commodo cursus magna, vel scelerisque nisl consectetur.
              </p> */}
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </MediaQuery>
    </Fragment>
  );
}


function Caruseli1() {
  return (
    <div id="ceshtjet">
      <div style={{ margin: "auto", width: "100%", marginTop: '0px'}}>
        <Doni />
      </div>
    </div>
  );
}

export default Caruseli1;

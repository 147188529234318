import React from "react";
// import "./punet.css";
import elc from "../assets/elc.jpeg"
import plmb from "../assets/plmb.jpg"
import ml from "../assets/ml.jpg"

export default function Punet() {
  return (
    <div id="sherbimet">
      <div>
    <div id="about" class="container" style={{ marginTop: '90px' }}>
      <div class="starter-template" style={{padding: '3rem, 1.5rem', textAlign: 'center'}}>
        <h1 class="mb-5">Sherbimet qe ofrojme</h1>
        <p>
          Merrni asistence teknike ne kohe reale te menjehershme. Zgjidhim dhe riparojme problemet tuaja qe lidhen me lavatrice, soba, dushe, lavastovilje, elektrike, hidraulike
        </p>
      </div>
      <div class="row text-center pb-4">
        <div class="col-md-12 col-lg-4 p-1">
          <img
            src={elc}
            class="rounded p-3 doni"
            alt="d"
            style={{ width: "350px", height: "350px" }}
          />
          <h2>Instalime elektrike</h2>
          <p>
         Kryejme Instalime elektrike ne apartamente dhe shtepi private! Konstatojme probleme te ndryshme qe keni me sistemin elektrik dhe i riparojme ne kohe reale.
          </p>
          {/* <a class="btn btn-info mb-5" href="/" role="button">
            Selection
          </a> */}
        </div>
        <div class="col-md-12 col-lg-4 p-1 ">
          <img
            src={ml}
            class="rounded p-3 doni"
            alt="d"
            style={{ width: "350px", height: "350px"}}
          />
          <h2>Riparim elektroshtepiake</h2>
          <p>
          Riparojme elektroshtepiake te ndryshme si : lavatrice, soba , dushe , lavastovilje dhe me teper. 
          Konstatimi i problemit kryhet brenda 24 oreve nga telefonata juaj dhe eshte pa pagese!
          </p>
          {/* <a class="btn btn-info mb-5" href="/" role="button">
            Selection
          </a> */}
        </div>
        <div class="col-md-12 col-lg-4 p-1">
          <img
            src={plmb}
            class="rounded p-3 doni"
            alt="d"
            style={{ width: "350px", height: "350px" }}
          />
          <h2>Riparim Hidraulike</h2>
          <p>
          Ofrojme te gjitha sherbimet hidraulike. Montojme instalimet hidraulike ne shtepite tuaja !   Kryejme riparime te pajisjeve te ndryshme hidraulike qe jane me probleme!
          </p>
          {/* <a class="btn btn-info mb-5" href="/" role="button">
            Selection
          </a> */}
        </div>
      </div>
    </div>
    </div>
    </div>
  );
}

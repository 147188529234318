import React from "react";
import rpr from "../assets/rpr.png"

export default function Footer() {
  return (
    <section id="footer" style={{ background: "#333", padding: "10px" }}>
      <div class="container">
        <div class="row">
          <div class="blogo col-xs-12 col-sm-12 col-md-12 col-lg-4">
            <a href="/">
              <img src={rpr} alt="Styles logo" style={{width: '120px', height: '120px'}} />
            </a>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
            <div class="cpt text-light text-center" style={{paddingTop: '49px',}}>
              <p>© 2023-Present Sandri inc.</p>
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
            <div class="ftaw text-light text-center">
              <a href="https://www.facebook.com/system22.net/">
                <i class="fa fa-facebook-square" style={{fontSize: "50px", color: 'red', paddingTop: '15px', paddingRight: '10px' }}></i>
              </a>
              {/* <a href="https://twitter.com/22ITSolutions1">
                <i class="fa fa-twitter-square" style={{fontSize: "50px", color: 'red', paddingTop: '15px', paddingRight: '10px' }}></i>
              </a> */}
              <a
                href="https://plus.google.com/+System22Net/posts">
                <i class="fa fa-google-plus-square" style={{fontSize: "50px", color: 'red', paddingTop: '15px', paddingRight: '10px' }}></i>
              </a>

              <a href="api.whatsapp.com/send?phone=+355682587994&text= Pershendetje, isha i interesuar per te riparuar instalimet/elektroshtepiaken time ? Mund te marr me shume informacion ju lutem?">
              <i class="fa fa-whatsapp whatsapp-icon" style={{fontSize: '48px'}}></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
